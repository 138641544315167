<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Displays</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="content-wrap">
            <header class="content-header">
              <h3>Statistiken</h3>
            </header>
            <div class="content-body">
              <div v-if="chart_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <div v-if="impressions">
                  <div ref="chart" style="width: 100%; height: 300px"></div>
                </div>
                <div v-else>
                  <p>There are no statistics yet.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>Ad-Pipeline</h3>
            </header>
            <div class="content-body">
              <div v-for="ad in ads" v-bind:key="ad.uuid">
                <div :class="'device-ad-wrap cf status-' + ad.status">
                  <div class="device-ad-img">
                    <p>
                      <img :src="ad.asset_file" style="max-height: 113px; object-fit: contain" />
                    </p>
                  </div>
                  <div class="device-ad-body">
                    <h3>
                      {{ ad.name }}
                      <span v-if="ad.ad_type == 'advertisment'" style="font-size: 14px; font-weight: 400; font-style: italic"
                        >({{ $t("global.adInternal") }})</span
                      >
                      <span v-if="ad.ad_type == 'campaign'" style="font-size: 14px; font-weight: 400; font-style: italic">({{ $t("global.adExternal") }})</span>
                    </h3>
                    <p>
                      Anzeige wurde
                      <span v-if="ad.status == 'created'">Angefragt</span>
                      <span v-if="ad.status == 'delivered'">Ausgeliefert</span>
                      <i v-if="ad.status == 'delivered'">
                        und von
                        <span>{{ ad.device_count }} Personen</span> gesehen</i
                      >.
                    </p>
                    <p>Angefragt: {{ ad.timestamp_fetched }} Uhr</p>
                    <p v-if="ad.status == 'delivered'">Ausgeliefert: {{ ad.timestamp_delivered }} Uhr</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>Impressionen</h3>
            </header>
            <div class="content-body">
              <p style="text-align: center; font-size: 16px">
                <strong>Impressionen Gesamt: {{ viewer_count }}</strong>
              </p>

              <table class="table">
                <thead>
                  <tr>
                    <th>Gesehen am</th>
                    <th>Device ID</th>
                    <th>RSSI</th>
                    <th>{{ $t("global.name") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="device_viewer in device_viewers" v-bind:key="device_viewer.uuid">
                    <td width="35%">
                      {{ device_viewer.measured_at_formatted }}
                    </td>
                    <td width="25%">
                      <router-link :to="'/admin/viewers/' + device_viewer.viewer.uuid" v-if="device_viewer.viewer" style="color: #444; font-weight: 600">{{
                        device_viewer.viewer.device_id
                      }}</router-link>
                    </td>
                    <td width="15%">{{ device_viewer.rssi }}</td>
                    <td width="25%">
                      <span v-if="device_viewer.viewer">{{ device_viewer.viewer.name }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>Stammdaten</h3>
            </header>
            <div class="content-body">
              <form @submit="update_device" class="form" method="post">
                <div class="form-wrap">
                  <label for="name">{{ $t("global.name") }}</label>
                  <input v-model="device.name" class="form-input" type="text" name="name" id="name" />
                </div>

                <div class="form-wrap">
                  <label for="device_id">Device ID</label>
                  <input v-model="device.device_id" class="form-input" type="text" name="device_id" id="device_id" />
                </div>

                <div class="form-wrap">
                  <label for="device_price">Preis</label>
                  <input v-model="device.device_price" class="form-input" type="text" name="device_price" id="device_price" />
                </div>

                <div class="form-wrap">
                  <label for="device_fee">Monatliche Gebühr</label>
                  <input v-model="device.device_fee" class="form-input" type="text" name="device_fee" id="device_fee" />
                </div>

                <div class="form-wrap select-wrap">
                  <label for="street_number">Status</label>
                  <select v-model="device.status" class="select-wrap">
                    <option value="created">Erstellt</option>
                    <option value="linked">Verbunden</option>
                    <option value="located">Lokalisiert</option>
                    <option value="running">Aktiv</option>
                    <option value="deleted">Ausgeblendet</option>
                  </select>
                </div>

                <div class="form-wrap select-wrap">
                  <label for="street_number">Wird auf Karte angezeigt</label>
                  <select v-model="device.visible_on_map" class="select-wrap">
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </select>
                </div>

                <div class="form-wrap select-wrap">
                  <label for="street_number">Bluetooth?</label>
                  <select v-model="device.bluetooth" class="select-wrap">
                    <option value="true">Aktiv</option>
                    <option value="false">Deaktiviert</option>
                  </select>
                </div>

                <div class="fifthy">
                  <div class="form-wrap">
                    <label for="lat">Latitude</label>
                    <input v-model="device.lat" class="form-input" type="text" name="lat" id="lat" />
                  </div>
                  <div class="form-wrap">
                    <label for="lng">Longitude</label>
                    <input v-model="device.lng" class="form-input" type="text" name="lng" id="lng" />
                  </div>
                </div>
                <div class="alert alert-info">
                  <p>
                    <strong>Achtung:</strong>
                    <br />
                    <span>Nach dem Ändern der Location ändert sich der Status und sollte wieder auf Aktiv gesetzt werden.</span>
                  </p>
                </div>

                <div class="tags-wrap cf">
                  <div class="row row-gutter-20">
                    <div v-for="industry in industries" v-bind:key="industry.id" class="col-12">
                      <div class="main-industries">
                        <div class="label">
                          <label :for="'checkbox-' + industry.id">{{ industry.name }}</label>
                          <input
                            type="checkbox"
                            :id="'checkbox-' + industry.id"
                            :value="industry.id"
                            v-model="device.industries"
                            @change="mainIndustryCheckbox(industry.id)"
                          />
                          <span class="dropdown-arrow" @click="toggleDropdown(industry.id, $event)">➡️</span>
                        </div>
                      </div>

                      <div class="sub-industries" :id="'dropdown-' + industry.id">
                        <div v-for="subIndustry in industry.industries" v-bind:key="subIndustry.id" class="sub-industry">
                          <div class="label">
                            <label :for="'checkbox-' + subIndustry.id">{{ subIndustry.name }}</label>
                            <input type="checkbox" :id="'checkbox-' + subIndustry.id" :value="subIndustry.id" v-model="device.industries" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p style="margin-bottom: 0; text-align: right">
                  <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round" />
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import echarts from "echarts";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      device: {},
      industries: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
      chart_loading: true,
      ads: [],
      echart_options: {
        color: ["#4DAA57", "#F3A712"],
        legend: {},
        tooltip: {},
        dataset: {
          source: [["date", "Einblendungen", "Impressionen"]],
        },
        xAxis: {
          type: "category",
        },
        yAxis: {},
        series: [{ type: "bar" }, { type: "bar" }],
      },
      impressions: null,
      device_viewers: [],
      viewer_count: 0,
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/devices/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.device = response.data.device;
        });
      this.get_industries(1);
      this.get_ads(1);
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/devices/" + this.$route.params.uuid + "/statistics", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.impressions = response.data;

          setTimeout(() => {
            var that = this;
            Object.keys(response.data).forEach(function (key) {
              that.echart_options.dataset.source.push([response.data[key].date, response.data[key].impressions, response.data[key].contacts]);
            });
            this.chart_loading = false;
            setTimeout(() => {
              this.init_chart();
            }, 500);
          }, 500);
        });
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/devices/" + this.$route.params.uuid + "/viewers", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.device_viewers = response.data.viewers;
          this.viewer_count = response.data.meta.pagination.total_objects;
        });
    },
    get_industries(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/industries?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.industries = response.data.industries;
          if (response.data.meta.pagination.links.next) {
            this.get_industries(response.data.meta.pagination.links.next.split("=")[1]);
          }
        });
    },
    get_ads(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/devices/" + this.$route.params.uuid + "/ads?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.ads = response.data.ads;
        });
    },
    update_device: function (e) {
      e.preventDefault();

      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/devices/" + this.$route.params.uuid, this.device, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gespeichert.",
          });
          this.get_data();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    init_chart() {
      var chart = echarts.init(this.$refs.chart);
      chart.setOption(this.echart_options);
    },
    toggleDropdown(id, event) {
      document.querySelector("#dropdown-" + id).classList.toggle("active");
      event.target.classList.toggle("active");
    },
    mainIndustryCheckbox(id) {
      var mainChecked = document.querySelector("#checkbox-" + id).checked;
      var currentSubIndustries = this.industries.find((x) => x.id === id).industries;

      currentSubIndustries.forEach((subIndustry) => {
        var checkbox = document.querySelector("#checkbox-" + subIndustry.id);

        if (mainChecked === true) {
          checkbox.checked = true;
          if (!this.device.industries.includes(subIndustry.id.toString())) {
            this.device.industries.push(subIndustry.id.toString());
          }
        } else {
          checkbox.checked = false;
          if (this.device.industries.includes(subIndustry.id.toString())) {
            var myIndex = this.device.industries.findIndex((x) => x == parseInt(subIndustry.id));
            this.$delete(this.device.industries, myIndex);
          }
        }
      });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.tags-wrap {
  margin-top: 20px;

  .label {
    display: inline-block;
    width: 100%;
    position: relative;
    background: #fff;
    padding: 20px 20px 20px 60px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;

    &:hover {
      cursor: default;
    }

    label:hover,
    input:hover {
      cursor: pointer;
    }

    label {
      font-size: 17px;
      font-weight: 600;
      margin: 0;
    }

    input {
      position: absolute;
      left: 23px;
      top: 23px;
    }
  }
}

.ad-ca {
  position: relative;

  input {
    position: absolute;
    bottom: 15px;
    left: 20px;
  }
}

.sub-industries {
  overflow: hidden;
  max-height: 0;

  &.active {
    max-height: 1000px;
  }
}

.sub-industry {
  padding-left: 30px;
}

.main-industries {
  .label {
    position: relative;

    h4 {
      margin-right: 20px;
    }

    .dropdown-arrow {
      transform: rotate(90deg) translate(-50%, -50%);
      position: absolute;
      top: 50%;
      right: 35px;

      &.active {
        transform: rotate(270deg) translate(70%, 40%);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 768px) {
  .fifthy {
    display: flex;

    > * {
      width: 49%;

      &:nth-of-type(1) {
        margin-right: 1%;
      }

      &:nth-of-type(2) {
        margin-left: 1%;
      }
    }
  }
}
</style>
