<template>
  <div class="tab-nav cf">
    <ul class="nav nav-tabs cf">
      <li><router-link to="/admin/dashboard">Dashboard</router-link></li>
      <li><router-link to="/admin/users">Benutzer</router-link></li>
      <li><router-link to="/admin/campaigns">Kampagnen</router-link></li>
      <li><router-link to="/admin/networks">Netze</router-link></li>
      <li><router-link to="/admin/displays">Displays</router-link></li>
      <li><router-link to="/admin/articles">News</router-link></li>
      <li><router-link to="/admin/coupons">Coupons</router-link></li>
      <li><router-link to="/admin/payouts">Payouts</router-link></li>
      <li><router-link to="/admin/viewers">Impressionen</router-link></li>
      <li><router-link to="/admin/settings">Settings</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "admin_nav",
  data() {
    return {
      user: {},
    };
  },
  methods: {},
  mounted() {},
};
</script>
